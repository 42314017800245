/* Dropdown.css */

.dropdown {
  position: relative;
  display: inline-block;
  padding: 0 5px;
}

.image-container {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border: 1px solid black;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  background-color: #fff;
  height: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  transition: all .5s ease;
  /* top: 35px; */
  width: 100%;

  top: 100%;
  padding: 5px 0;
  list-style: none;
  margin: 0;
  z-index: 999;
  border-radius: 7px;
}

.dropdown:hover .dropdown-menu {
  height: fit-content;
  opacity: 1;
  transform: translateY(0);
}

.dropdown-menu-close {
  max-height: 0;
}

.dropdown-menu-open {
  max-height: 200px;
}

.dropdown-menu li {
  padding: 5px 4px;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}
