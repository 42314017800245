* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: math;
    scroll-behavior: smooth;
}

body {
    min-height: 100vh;
    background: linear-gradient(rgba(255, 255, 255, .2), rgba(255, 255, 255, .2)), url(./assets/bgimage.jpg) no-repeat center top;
    background-size: cover;
}

#root {
    height: 100%;
    min-height: 100vh;
}

.App {
    position: relative;
    min-height: 100vh;
    height: 100%;
    top: 0;
    /* background: #e5eae3 url(./assets/bgimage.jpg) no-repeat center top; */
    
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1400px) {
        background-size: auto;
    }
}

button {
    cursor: pointer;
}

.content {
    margin-top: 60px;
}